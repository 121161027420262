<template>
  <v-sheet class="pa-6">
    <List v-if="$store.getters.g_role_type == 'STUDENT'" type="news" />

    <div v-else>
      <v-tabs v-model="tab" center-active>
        <v-tab>
          <v-icon class="mr-2">mdi-clipboard-text</v-icon>
          {{ $t("routes.news") }}
        </v-tab>
        <v-tab>
          <v-icon class="mr-2">mdi-note-plus</v-icon>
          {{ $t("news.add_news") }}
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab" touchless>
        <v-tab-item>
          <List :tab="tab" type="news" />
        </v-tab-item>
        <v-tab-item>
          <Data :tab="tab" type="news" />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-sheet>
</template>

<script>
import List from "./components/list";
import Data from "./components/data";
import i18n from "@/i18n";

export default {
  metaInfo: {
    title: i18n.t("routes.news"),
    titleTemplate: `%s | ${process.env.VUE_APP_APPLICATION_NAME}`
  },
  components: { List, Data },
  data() {
    return {
      tab: 0
    };
  }
};
</script>
